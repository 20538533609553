import { setScreen } from '@services/router';
import styles from './Instructions.module.scss';
import { strings } from '@services/language';
import { useInstructionsLogic } from './logics';
import { NavTabs } from '@components';

setScreen('/instructions', () => {
  const { tabList } = useInstructionsLogic();

  return (
    <div className={styles.container}>
      <NavTabs title={strings.INSTRUCTIONS} navTabList={tabList} />
    </div>
  );
});
