import styles from './MapICA.module.scss';
import { useState, useEffect, useRef, useContext } from 'react';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { createPulsingDot, markerSvg } from './layers';
import * as turf from '@turf/turf';

import {
  getAWSLocationToken,
  GetAWSLocationTokenResponse,
  useAPI,
  useResult,
} from '@services/api';
import { Signer } from '@aws-amplify/core';
import { RadarContext } from '@screens/radar';
import { ExposureSite } from '@services/models';
import moment from 'moment';
const AWS_MAP_ID = process.env.REACT_APP_AWS_MAP_ID || 'testMap';

export function MapICA() {
  const { distance, siteResult, selectedLocation, submited } =
    useContext(RadarContext);

  const [awsLocaltionCredentials, setAWSCredentials] =
    useState<GetAWSLocationTokenResponse | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const api = useAPI(getAWSLocationToken);
  const mapRef = useRef<HTMLDivElement>(null);

  // get aws map request interceptor
  function transformRequest(url: string, resourceType: string) {
    if (resourceType === 'Style' && !url.includes('://')) {
      // resolve to an AWS URL
      url = `https://maps.geo.ap-southeast-2.amazonaws.com/maps/v0/maps/${url}/style-descriptor`;
    }

    if (url.includes('amazonaws.com')) {
      // only sign AWS requests (with the signature as part of the query string)
      return {
        url: Signer.signUrl(url, {
          access_key: awsLocaltionCredentials?.ack,
          secret_key: awsLocaltionCredentials?.sack,
          session_token: awsLocaltionCredentials?.sst,
        }),
      };
    }

    // don't sign
    return { url };
  }

  // get the AWS credentials
  useEffect(() => {
    api.request();
  }, []);

  useEffect(() => {
    const expireTimeout = setTimeout(() => {
      if (awsLocaltionCredentials) {
        api.request();
      }
    }, (moment(awsLocaltionCredentials?.expi).unix() - moment().unix() - 5) * 1000);

    return () => {
      clearTimeout(expireTimeout);
    };
  }, [awsLocaltionCredentials]);

  useResult(api.data, d => {
    if (d) {
      setAWSCredentials(d);
    }
  });

  useEffect(() => {
    if (!awsLocaltionCredentials || !siteResult || mapLoaded) return;

    setMapLoaded(true);

    const map = new maplibregl.Map({
      preserveDrawingBuffer: true,
      container: mapRef.current!,
      zoom: 0.3,
      center: selectedLocation,
      style: AWS_MAP_ID, // this is the map id we created in aws location service
      transformRequest: transformRequest,
    });

    // set center marker
    const centerEl = document.createElement('div');
    centerEl.innerHTML = markerSvg;
    centerEl.classList.add('centerMarker');
    centerEl.style.width = 10 + 'px';
    centerEl.style.height = 10 + 'px';

    // draw circle distance
    const radius = distance;
    const circle = turf.circle(selectedLocation, radius, {
      steps: 64,
      units: 'kilometers',
    });

    const circleLine = turf.lineString(circle.geometry.coordinates[0]);
    const bbox = turf.bbox(circleLine);

    // set distance marker
    const distanceEl = document.createElement('div');
    distanceEl.classList.add('distance-badge');
    distanceEl.innerHTML = `${radius}km`;

    const distanceLocation = turf.destination(
      selectedLocation,
      radius + (radius * 8) / 100,
      45,
      {
        units: 'kilometers',
      }
    );
    new maplibregl.Marker(distanceEl, { anchor: 'left' })
      .setLngLat(distanceLocation.geometry.coordinates as LngLatLike)
      .addTo(map);
    new maplibregl.Marker(centerEl).setLngLat(selectedLocation).addTo(map);

    map.addControl(new maplibregl.FullscreenControl(), 'top-left');
    map.addControl(
      new maplibregl.NavigationControl({ showCompass: false }),
      'top-left'
    );

    const pulsingDot = createPulsingDot(map);

    centerEl.addEventListener('click', e => {
      e.stopPropagation();
      // @ts-ignore
      map.fitBounds(bbox, { padding: 60 });
    });

    map.on('load', function () {
      map.addSource('circleFill', { type: 'geojson', data: circle });
      map.addLayer({
        id: 'circleFillPoints',
        type: 'fill',
        source: 'circleFill',
        paint: {
          'fill-color': '#5DBC9C',
          'fill-opacity': 0.25,
        },
      });
      map.addSource('circleLine', { type: 'geojson', data: circleLine });
      map.addLayer({
        id: 'circleLinePoints',
        type: 'line',
        source: 'circleLine',
        paint: {
          'line-color': '#2A8164',
          'line-width': 2,
        },
      });

      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
      // add a clustered GeoJSON source for a sample set of covid
      map.addSource('covid', {
        type: 'geojson',
        data: convertToGeojson(siteResult.data),
        cluster: true,
        clusterRadius: 80,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'covid',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#fff',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            15,
            100,
            28,
            750,
            44,
          ],
          'circle-stroke-color': '#D2440F',
          'circle-stroke-width': [
            'step',
            ['get', 'point_count'],
            3,
            100,
            4,
            750,
            6,
          ],
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'covid',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          'text-font': ['Noto Sans Bold'],
          'text-size': ['step', ['get', 'point_count'], 12, 100, 20, 750, 28],
        },
        paint: {
          'text-color': '#D2440F',
        },
      });

      // circle and symbol layers for rendering individual covid (unclustered points)
      map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'covid',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'pulsing-dot',
        },
      });

      // add popup on hover
      const popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 10,
        maxWidth: '256px',
      });

      map.on('mouseenter', 'points', e => {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = 'pointer';

        // Copy coordinates array.
        // @ts-ignore
        const coordinates = e.features![0].geometry.coordinates!.slice();
        const description =
          e.features![0].properties?.description ||
          `<div class="map-popup">
          <strong>Exposure site</strong>
          <p>${coordinates[0]}</p>
          <p>204 Devonshire St, Surry Hills NSW 2010, Australia</p>
          <p class="grey-text">Source: NSW Health Authority</p>
          </div>
          `;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on('mouseleave', 'points', () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });

      map.on('click', 'clusters', e => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0]?.properties?.cluster_id;
        map
          .getSource('covid')
          // @ts-ignore
          .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
            if (err) return;

            map.easeTo({
              // @ts-ignore
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      map.on('data', onDataLoaded);

      function onDataLoaded(e: maplibregl.MapDataEvent & maplibregl.EventData) {
        if (e.sourceId !== 'covid' || !e.isSourceLoaded) return;
        // @ts-ignore
        map.fitBounds(bbox, { padding: 60 });
        map.off('data', onDataLoaded);
      }

      map.on('zoom', e => {
        const centerMarkers = document.querySelectorAll(
          '.centerMarker'
        ) as NodeListOf<HTMLElement>;
        centerMarkers.forEach(centerMarker => {
          const width = map.getZoom() > 15 ? 60 : (60 * map.getZoom()) / 15;
          centerMarker.style.width = width + 'px';
          centerMarker.style.height = width + 'px';
        });
      });
    });
  }, [awsLocaltionCredentials, submited, siteResult]);

  return <div ref={mapRef} id="map" className={styles.map}></div>;
}

function convertToGeojson(siteResult: ExposureSite[]) {
  const features = siteResult.map(site => ({
    type: 'Feature' as 'Feature',
    properties: {
      description: `<div class="map-popup">
        <strong>Exposure site</strong>
        <p>${site.loc_name}, ${site.loc_addr}, ${site.loc_suburb}</p>
        <p class="grey-text">Source: ${site.exp_src_url}</p>
        </div>
        `,
    },
    geometry: {
      type: 'Point' as 'Point',
      coordinates: [site.loc_lon, site.loc_lat],
    },
  }));

  return {
    type: 'FeatureCollection' as 'FeatureCollection',
    features: features,
  };
}
