import { API } from '../types';

interface Params {
  pre_date_exposure_sites: {
    loc_name: string;
    loc_addr: string;
    exp_date: string;
  }[];
  exposure_sites: {
    loc_name: string;
    loc_addr: string;
    exp_date: string;
  }[];
  from_date: string;
  to_date: string;
  distance: number;
  address: string;
  reference_number: string;
  map_image_path: string;
  selected_unique_pho_ids: string[];
  all_unique_pho_ids: string[];
}

interface Response {
  success: boolean;
}

export function sendReportAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/send-reports',
        method: 'POST',
        data: params,
      });
      return { data: data };
    },
    getMockData: () => {
      return { success: true };
    },
  };
}
