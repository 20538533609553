import styles from './Button.module.scss';

type ButtonProps = {
  type?: 'primary' | 'outlined';
  className?: string;
  label: string;
  width?: string;
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function Button({
  type = 'primary',
  className,
  label,
  width,
  disabled = false,
  handleClick = () => {},
}: ButtonProps) {
  return (
    <button
      className={`${styles.container} ${styles[type]} ${
        className ? className : ''
      } ${disabled ? styles.disabled : ''}`}
      style={{ minWidth: width || 'initial' }}
      onClick={e => handleClick(e)}
    >
      {label}
    </button>
  );
}
