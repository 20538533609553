import { Link } from 'react-router-dom';
import {
  Button,
  DatePickerICA,
  Dropdown,
  Input,
  Modal,
  RangeSlider,
} from '@components';
import { strings } from '@services/language';
import { toRoute } from '@services/router';
import { useRadarFormLogic } from './logics';
import styles from './RadarForm.module.scss';

export default function RadarForm() {
  const {
    bindRefNumber,
    bindAddress,
    addressList,
    setDatePeriodPicker,
    datePeriodError,
    setDatePeriodError,
    setDistanceSlider,
    onSubmit,
    errorModalRef,
    searching,
    handleFocusAddress,
  } = useRadarFormLogic();
  return (
    <div className={styles.container}>
      <h1>{strings.BUSINESS_INTERRUPTION_INSURANCE_COVID19_RADAR}</h1>
      <div className={styles.text}>
        {strings.USE_THIS_TOOL + ' ' + strings.THE_RESULTS}
      </div>
      <div className={styles.text}>
        {strings.IF_YOU_NEED_HELP_PLEASE_REFER + ' '}
        <Link to={toRoute('/instructions')}>
          {strings.INSTRUCTIONS.toLowerCase()}
        </Link>
        .
      </div>

      <div className={styles.row}>
        <Input
          id="refNumber"
          label={strings.REFERENCE_NUMBER}
          {...bindRefNumber}
        />
        <DatePickerICA
          label={strings.ASSESSMENT_DATE_PERIOD}
          error={datePeriodError}
          onSelectDate={selectedDate => {
            setDatePeriodError('');
            setDatePeriodPicker(selectedDate);
          }}
        />
      </div>

      <Dropdown
        id="address"
        label={strings.ADDRESS_OF_BUSINESS_PREMISE}
        list={addressList}
        loading={searching}
        {...bindAddress}
        handleFocus={() => {
          handleFocusAddress();
        }}
      />

      <div className={styles.slider}>
        <RangeSlider
          label={strings.DISTANCE_FROM_BUSINESS_PREMISE}
          handleChange={value => setDistanceSlider(value)}
        />
      </div>

      <Button
        className={styles.resultButton}
        label={strings.SHOW_RESULTS}
        width="129px"
        handleClick={onSubmit}
      />

      <Modal
        ref={errorModalRef}
        header={<h2>{strings.ERROR}</h2>}
        body={<div>{strings.OOPS_WRONG}</div>}
        footer={
          <>
            <Button
              label={strings.OKAY}
              width="128px"
              handleClick={() => {
                errorModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}
