import { PHO } from '@services/models';
import { API } from '../types';

interface Params {
  limit?: number;
  offset?: number;
  lat: number;
  lon: number;
  distance: number;
  from_date: string;
  to_date: string;
}

interface Response {
  data: PHO[];
  total_count: number;
}

export function getPHOsAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/phos',
        method: 'GET',
        params: params,
      });
      return { data: data };
    },
    getMockData: () => {
      return {
        data: [
          {
            geo_id: 'e41bfe1e-0b1f-4db8-877a-93d4515250dd',
            geog_name: 'New South Wales',
            geog_level: 'STATE',
            unique_id: '15032020-1-NSW',
            first_time: '1',
            pho_id: 'Public Health (COVID-19 Public Events) Order 2020',
            pho_id_amendment_number: undefined,
            pho_type: 'Restricted Gathering and movement',
            types_of_commerce_or_region: '[Public Events]',
            start_time_aest: undefined,
            start_date: '20200316',
            end_date: '20200614',
            short_desc_pho_name:
              'Public Health (COVID-19 Public Events) Order 2020',
            max_persons: '[500]',
            persons_sqm: undefined,
            doc_url:
              'https://legislation.nsw.gov.au/file/Public%20Health%20(COVID-19%20Public%20Events)%20Order%202020.pdf',
            s3_url:
              'https://legislation.nsw.gov.au/file/Public%20Health%20(COVID-19%20Public%20Events)%20Order%202020.pdf',
            source: 'Legislation',
            date_retrieved: '20200927',
            wfh_direction: 'No',
            exemptions: undefined,
          },
        ],
        total_count: 10,
      };
    },
  };
}
