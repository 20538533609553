import { Button } from '@components';
import { strings } from '@services/language';
import { setScreen } from '@services/router';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NotFound.module.scss';

setScreen('/notfound', () => {
  const history = useHistory();

  useEffect(() => {
    document.body.style.background = '#2A8164';

    return () => {
      document.body.style.background = 'initial';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.graphic}></div>
      <div className={styles.wrapper}>
        <div className={styles.hugeText}>404</div>
        <div className={styles.bigText}>{strings.PAGE_NOT_FOUND}</div>
        <div className={styles.normalText}>{strings.PAGE_UNAVAILABLE}</div>
        <Button
          label={strings.GO_TO_HOMEPAGE}
          handleClick={() => history.push('/')}
        />
      </div>
    </div>
  );
});
