import { useEffect } from 'react';

/**
 * Hook that show hide Loader
 */
export function useLoading(show: boolean) {
  useEffect(() => {
    if (show) {
      document.body.classList.add('loading-show');
    } else {
      document.body.classList.remove('loading-show');
    }
  }, [show]);
}
