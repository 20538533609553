import { authExchangeTokenAPI, useAPI, useResult } from '@services/api';
import { useLoading } from '@services/hooks';
import { setScreen } from '@services/router';
import { parseJwt } from '@services/utils';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

setScreen('/auth/login', params => {
  const history = useHistory();
  const api = useAPI(authExchangeTokenAPI);

  useLoading(api.loading);

  if (params.token) {
    const payload = parseJwt(params.token);
    payload.email && localStorage.setItem('email', payload.email);
    api.request({ shortToken: params.token });
  }

  useResult(api.data, d => {
    if (d.accessToken && d.refreshToken) {
      localStorage.setItem('token', d.accessToken);
      localStorage.setItem('refreshToken', d.refreshToken);
      history.push('/');
    }
  });

  return <div></div>;
});
