import ReactMarkdown from 'react-markdown';
import { strings } from '@services/language';
import remarkGfm from 'remark-gfm';
import imgInstruction1 from '@images/instruction1.png';
import imgInstruction2 from '@images/instruction2.png';
import imgInstruction3 from '@images/instruction3.png';
import imgInstruction4 from '@images/instruction4.png';
import imgInstruction5 from '@images/instruction5.png';
import imgInstruction6 from '@images/instruction6.png';
import { getAWSLocationToken, useAPI } from '@services/api';
import { useEffect } from 'react';

export function useInstructionsLogic() {
  const aboutContent = `
## Instructions for COVID-19 Radar Tool

The COVID-19 Radar Tool can be used  to determine the presence of COVID-19 and any Public Health Orders (PHOs) relevant to the policy area. The results can be used to help assess a COVID-19 related claim under a business interruption (BI) insurance policy.

## About the COVID-19 Radar (COVID-19 BI Mapping Tool)

The COVID-19 Radar Tool may help you demonstrate within a nominated date range the:

- COVID-19 hotspots in Australia within a 0-50km radius of a business address.
- Relevant PHOs for the searched address location.

The  COVID-19 Tool has been built with data sourced from public health data and orders published by Federal, State and Territory governments.

This information will enable both policyholders and insurers to determine whether two of the essential triggers in many BI policies will be activated, that is the presence of COVID-19 in the policy area and the PHOs applicable to that business address.

The COVID-19 Tool will benefit policyholders and insurers through an expedited process of claim assessment resulting in a significant saving of time in the triage and assessment of a submitted BI Claim. The output of the report generated by the COVID-19 Radar Tool could be used to support the decision by the insurer on the claim and would be available to the policyholder and to any external parties, who may have to determine BI complaints or related disputes.

The COVID-19 Radar Tool uses the following terms:

- **Exposure Sites** of COVID-19 cases which have been identified primarily through State and Territory Health Departments, by reference to relevant postcodes and/other geographic COVID-19 reporting levels as well as date; and
- **Public Health Orders** which have been sourced primarily from each State and Territory government, catalogued and stored by reference to State/Territory, location, date and type of commerce (where available).

The COVID-19 Radar Tool has collated records of &quot;Exposure Sites&quot; and &quot;Public Health Orders&quot; from March 2020 to July 2021 where such information is available.

The COVID-19 Radar Tool displays the location of a given business address and the relevant policy area (defined as either "at the premises" or  the radius from the business premise address). Relevant Exposure site(s) and /or PHO(s) within or intersecting with the defined policy area are then displayed.

The results of a search conducted on the COVID-19 Radar Tool can be used to assess a COVID-19 related claim under a BI policy.
`;

  const howToUseContent = `
## How to use the COVID-19 Radar

#### Address of the insured premises

Enter the address of the business premise(s) which is identified in the BI policy as the insured premises. The search function will allow the user to find the address by typing the street number, street name, then postcode. The search will identify the building (physical) address and should not be the postal address.

#### Distance covered by policy

Select the unit of measurement for the distance covered by the policy. The calculator gives the user the option in 5, 10, 20 and 50 kilometers. Enter the distance covered by the policy. For example, if the policy requires an outbreak of disease within 5 kilometres of the Premises, please select 5 kilometres in the slider.

#### Assessment Date

Select the Assessment Date range or period of coverage using the calendar. The Assessment Date is a user defined input to be entered by insurers. This date will depend on the terms of the policy and the claim. The Assessment Date will often be the claim period, however, there are cases where exposure sites exist just before / after the user’s input time period. In most cases the Assessment Date will commence on the first date that the insured business suffered interruption or interference as a result of COVID-19.

Once you have entered the information above, click &quot;**Show Results**&quot;.

![imgInstruction1](${imgInstruction1})`;

  const mapViewContent = `
## Map View

The COVID-19 Radar Tool will produce a map with the results shown in the centre of the web page. The map will show the business premises at the centre with a circle to show the relevant business address entered by the user for the calculation.

The COVID-19 Radar Tool will list out the relevant PHOs for the address on the left-hand side of the page. These PHOs are applicable for the date range though may not be applicable to the circumstances of the policy, depending on the wording of the policy. The user can select the applicable PHOs to be included and referred to in the report.

![imgInstruction2](${imgInstruction2})`;

  const resultsViewContent = `
## Results View

| | |
| :--- | :--- |
| ![imgInstruction3](${imgInstruction3}) | On the right-hand side of the page, the user can review the results in detail. The user can find more information about what the results mean below. ![imgInstruction4](${imgInstruction4}) |

#### How to read the results

The cases for each local tier local authority will be shown under the following headings:

| **Heading** | **Description** |
| :--- | :--- |
| | |
| Applicable Public Health Order | An order directed by a Federal, State or Territory Health Authority to limit the spread of COVID-19. These PHOs includes details of the location and industries affected by the terms of the order |
| Applicable Exposure Sites in policy area | Locations of all clusters of COVID-19 cases, where users can see the individual Exposure Sites. |`;

  const reportContent = `
## Report generation and Email

The COVID-19 Radar Tool will send via email with a Reference Number in the email header, the following:

- A PDF report with list of Exposure Sites and list of applicable PHOs; and
- Locally stored copies of the PHOs that were selected.

| | |
| :--- | :--- |
| The data in the email will be available for 7 days from the date that the email and report were generated. ![imgInstruction5](${imgInstruction5}) | ![imgInstruction6](${imgInstruction6}) |

A link to the data will be provided within the email. After 7 days the user will be required to generate another updated report in order to download a copy.
`;

  const tabList = [
    {
      id: 'about',
      label: strings.ABOUT,
      content: <ReactMarkdown children={aboutContent} />,
    },
    {
      id: 'howToUse',
      label: strings.HOW_TO_USE,
      content: <ReactMarkdown children={howToUseContent} />,
    },
    {
      id: 'mapView',
      label: strings.MAP_VIEW,
      content: <ReactMarkdown children={mapViewContent} />,
    },
    {
      id: 'resultsView',
      label: strings.RESULTS_VIEW,
      content: (
        <ReactMarkdown
          children={resultsViewContent}
          remarkPlugins={[remarkGfm]}
        />
      ),
    },
    {
      id: 'report',
      label: strings.REPORT,
      content: (
        <ReactMarkdown children={reportContent} remarkPlugins={[remarkGfm]} />
      ),
    },
  ];

  const awsCredentialsApi = useAPI(getAWSLocationToken);
  useEffect(() => {
    awsCredentialsApi.request();
  }, []);

  return { tabList };
}
