import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getScreenProps as gsp } from './configs';
import { AuthRoute, MainRoute } from './utils';

export function RootStack() {
  return (
    <Router>
      <Switch>
        <AuthRoute {...gsp('/login')} />
        <AuthRoute {...gsp('/auth/login')} />
        <MainRoute {...gsp('/radar')} path={['/', '/radar']} />
        <MainRoute {...gsp('/instructions')} />
        <MainRoute {...gsp('/faq')} />
        <Route {...gsp('/notfound')} path="*" />
      </Switch>
    </Router>
  );
}
