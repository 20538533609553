import { useContext, useEffect, useRef, useState } from 'react';
import { useDropdown, useInput, useLoading } from '@services/hooks';
import { strings } from '@services/language';
import {
  getAWSLocationToken,
  GetAWSLocationTokenResponse,
  getPHOsAPI,
  getSitesAPI,
  useAPI,
  useResult,
} from '@services/api';
import axios, { AxiosResponse } from 'axios';
import { Signer } from '@aws-amplify/core';
import { useDebounce } from '@services/hooks';
import { ExposureSite, GetAWSPlacesResponse, PHO } from '@services/models';
import { RadarContext } from '..';
import moment, { Moment } from 'moment';
import { RangeModifier } from 'react-day-picker';

const limit = 1000;
const limitRange = {
  from: new Date(2020, 0),
  to: new Date(2021, 7, 31),
};

export function useRadarFormLogic() {
  const [awsCredentials, setAWSCredentials] =
    useState<GetAWSLocationTokenResponse | null>(null);
  const { states: refNumber, bind: bindRefNumber } = useInput();
  const { states: address, bind: bindAddress } = useDropdown(
    '',
    onAddressChange
  );

  const [datePeriodPicker, setDatePeriodPicker] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [datePeriodError, setDatePeriodError] = useState('');
  const [addressList, setAddressList] = useState<
    { content: string; value: any }[]
  >([]);
  const [searching, setSearching] = useState(false);
  const [distanceSlider, setDistanceSlider] = useState(5);
  const [offsetSite, setOffsetSite] = useState(0);
  const [siteResultTemp, setSiteResultTemp] = useState<ExposureSite[]>([]);
  const [offsetPastSite, setOffsetPastSite] = useState(0);
  const [pastSiteResultTemp, setPastSiteResultTemp] = useState<ExposureSite[]>(
    []
  );
  const [offsetPHO, setOffsetPHO] = useState(0);
  const [phoResultTemp, setPhoResultTemp] = useState<PHO[]>([]);

  const {
    setDatePeriod,
    setDistance,
    PHOResult,
    setPHOResult,
    siteResult,
    setSiteResult,
    pastSiteResult,
    setPastSiteResult,
    setSelectedLocation,
    submited,
    setSubmited,
    setSelectedAddress,
    setSelectedRefNumber,
  } = useContext(RadarContext);

  const awsCredentialsApi = useAPI(getAWSLocationToken);
  const getPHOsApi = useAPI(getPHOsAPI);
  const getSitesApi = useAPI(getSitesAPI);
  const getPastSitesApi = useAPI(getSitesAPI);

  const errorModalRef = useRef<any>(null);

  useEffect(() => {
    if (
      datePeriodPicker.from &&
      datePeriodPicker.to &&
      (datePeriodPicker.from > datePeriodPicker.to ||
        !isBetweenLimitRange(moment(datePeriodPicker.from)) ||
        !isBetweenLimitRange(moment(datePeriodPicker.to)))
    ) {
      setDatePeriodError(strings.PLEASE_ENTER_A_VALID_DATE);
    } else {
      setDatePeriodError('');
    }
  }, [datePeriodPicker]);

  const handleFocusAddress = () => {
    if (
      (awsCredentials &&
        moment(awsCredentials.expi).unix() <= moment().unix()) ||
      !awsCredentials
    ) {
      awsCredentialsApi.request();
    }
  };

  // get the AWS credentials
  useEffect(() => {
    awsCredentialsApi.request();
  }, []);

  useEffect(() => {
    if (siteResult) {
      setSubmited(submited + 1);
    }
  }, [PHOResult, siteResult, pastSiteResult]);

  useEffect(() => {
    if (awsCredentialsApi.error && !awsCredentialsApi.loading) {
      if (awsCredentialsApi.error.includes('401')) {
      } else {
        errorModalRef.current.showModal();
      }
    }
  }, [awsCredentialsApi.loading]);

  useResult(awsCredentialsApi.data, d => {
    if (d) {
      setAWSCredentials(d);
    }
  });

  useResult(getPHOsApi.data, d => {
    if (d) {
      setPhoResultTemp([...phoResultTemp, ...d.data]);
      const pages = Math.ceil(d.total_count / limit);
      if (offsetPHO > pages - 1) {
        setPHOResult({
          data: [...phoResultTemp, ...d.data],
          total: d.total_count,
        });
        setPhoResultTemp([]);
        setOffsetPHO(0);
      } else {
        const params = {
          limit,
          offset: offsetPHO * limit,
          lat: address.dropdownValue[1],
          lon: address.dropdownValue[0],
          distance: distanceSlider,
          from_date: datePeriodPicker.from!.toISOString(),
          to_date: datePeriodPicker.to!.toISOString(),
        };
        getPHOsApi.request(params);
        setOffsetPHO(offsetPHO + 1);
      }
    }
  });

  useResult(getSitesApi.data, d => {
    if (d) {
      setSiteResultTemp([...siteResultTemp, ...d.data]);
      const pages = Math.ceil(d.total_count / limit);
      if (offsetSite > pages - 1) {
        const sortedSites = [...siteResultTemp, ...d.data].sort(sortSite);
        setSiteResult({
          data: sortedSites,
          total: d.total_count,
        });
        setSiteResultTemp([]);
        setOffsetSite(0);
      } else {
        const params = {
          limit,
          offset: offsetSite * limit,
          lat: address.dropdownValue[1],
          lon: address.dropdownValue[0],
          distance: distanceSlider,
          from_date: datePeriodPicker.from!.toISOString(),
          to_date: datePeriodPicker.to!.toISOString(),
        };
        getSitesApi.request(params);
        setOffsetSite(offsetSite + 1);
      }
    }
  });

  useResult(getPastSitesApi.data, d => {
    if (d) {
      setPastSiteResultTemp([...pastSiteResultTemp, ...d.data]);
      const pages = Math.ceil(d.total_count / limit);
      if (offsetPastSite > pages - 1) {
        const sortedPastSites = [...pastSiteResultTemp, ...d.data].sort(
          sortSite
        );
        setPastSiteResult({
          data: sortedPastSites,
          total: d.total_count,
        });
        setPastSiteResultTemp([]);
        setOffsetPastSite(0);
      } else {
        const params = {
          limit,
          offset: offsetPastSite * limit,
          lat: address.dropdownValue[1],
          lon: address.dropdownValue[0],
          distance: distanceSlider,
          from_date: moment(datePeriodPicker.from!)
            .subtract(2, 'week')
            .toISOString(),
          to_date: moment(datePeriodPicker.from!)
            .subtract(1, 'day')
            .toISOString(),
        };
        getPastSitesApi.request(params);
        setOffsetPastSite(offsetPastSite + 1);
      }
    }
  });

  const debounceFetchPlaces = useDebounce((value: string) => {
    !value && setAddressList([]);
    if (awsCredentials && value) {
      setSearching(true);
      const url =
        'https://places.geo.ap-southeast-2.amazonaws.com/places/v0/indexes/testIndex/search/text';

      axios(
        Signer.sign(
          {
            method: 'POST',
            url,
            data: JSON.stringify({
              Text: value,
              MaxResults: 10,
              FilterCountries: ['AUS'],
            }),
          },
          {
            access_key: awsCredentials?.ack,
            secret_key: awsCredentials?.sack,
            session_token: awsCredentials?.sst,
          }
        )
      )
        .then(function (response: AxiosResponse<GetAWSPlacesResponse>) {
          const results = response.data.Results.map(r => ({
            content: r.Place.Label,
            value: r.Place.Geometry.Point,
          }));
          setAddressList(results);
          setSearching(false);
        })
        .catch(function (error) {
          setSearching(false);
          errorModalRef.current.showModal();
          console.error(error);
        });
    }
  }, 500);

  function onAddressChange(name: string, value: string) {
    debounceFetchPlaces(value);
  }

  function validate() {
    !refNumber.value
      ? refNumber.setError(strings.PLEASE_ENTER_A_NUMBER)
      : refNumber.setError('');
    !datePeriodPicker.from || !datePeriodPicker.to
      ? setDatePeriodError(strings.PLEASE_ENTER_A_DATE)
      : datePeriodPicker.from > datePeriodPicker.to ||
        !isBetweenLimitRange(moment(datePeriodPicker.from)) ||
        !isBetweenLimitRange(moment(datePeriodPicker.to))
      ? setDatePeriodError(strings.PLEASE_ENTER_A_VALID_DATE)
      : setDatePeriodError('');
    !address.dropdownValue
      ? address.setError(strings.PLEASE_SELECT_AN_ADDRESS)
      : address.setError('');

    return (
      refNumber.value &&
      datePeriodPicker.from &&
      datePeriodPicker.to &&
      datePeriodPicker.from <= datePeriodPicker.to &&
      isBetweenLimitRange(moment(datePeriodPicker.from)) &&
      isBetweenLimitRange(moment(datePeriodPicker.to)) &&
      address.dropdownValue
    );
  }
  function onSubmit() {
    if (validate()) {
      setPHOResult(undefined);
      setSiteResult(undefined);
      setSelectedLocation([address.dropdownValue[0], address.dropdownValue[1]]);
      setSelectedRefNumber(refNumber.value);
      setSelectedAddress(address.value);
      setDistance(distanceSlider);
      setDatePeriod(datePeriodPicker);

      const params = {
        limit,
        lat: address.dropdownValue[1],
        lon: address.dropdownValue[0],
        distance: distanceSlider,
        from_date: datePeriodPicker.from!.toISOString(),
        to_date: datePeriodPicker.to!.toISOString(),
      };
      getPHOsApi.request(params);
      getSitesApi.request(params);
      setOffsetSite(1);
      setOffsetPHO(1);

      const pastParams = {
        limit,
        lat: address.dropdownValue[1],
        lon: address.dropdownValue[0],
        distance: distanceSlider,
        from_date: moment(datePeriodPicker.from!)
          .subtract(2, 'week')
          .toISOString(),
        to_date: moment(datePeriodPicker.from!)
          .subtract(1, 'day')
          .toISOString(),
      };
      getPastSitesApi.request(pastParams);
      setOffsetPastSite(1);
    }
  }

  useLoading(
    getPHOsApi.loading || getSitesApi.loading || getPastSitesApi.loading
  );

  return {
    bindRefNumber,
    bindAddress,
    addressList,
    setDatePeriodPicker,
    datePeriodError,
    setDatePeriodError,
    setDistanceSlider,
    onSubmit,
    errorModalRef,
    searching,
    handleFocusAddress,
  };
}

function sortSite(a: ExposureSite, b: ExposureSite) {
  return moment(b.exp_date, 'YYYYMMDD').diff(
    moment(a.exp_date, 'YYYYMMDD'),
    'day'
  );
}

function isBetweenLimitRange(date: Moment) {
  return date.isBetween(
    moment(limitRange.from),
    moment(limitRange.to),
    'day',
    '[]'
  );
}
