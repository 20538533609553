import { API } from '../types';

interface Params {
  shortToken: string;
}

interface Response {
  accessToken: string;
  refreshToken: string;
}

export function authExchangeTokenAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/auth/short-token/exchange',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${params.shortToken}`,
        },
      });
      return { data: data };
    },
    getMockData: () => {
      return { accessToken: 'accessToken', refreshToken: 'refreshToken' };
    },
  };
}
