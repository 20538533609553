import { Dispatch, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as CalenderIcon } from '@images/calender.svg';
import { ReactComponent as ArrowIcon } from '@images/chevron.svg';
import 'react-day-picker/lib/style.css';
import './DatePickerICA.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  DateUtils,
  DayPickerProps,
  RangeModifier,
  WeekdayElementProps,
} from 'react-day-picker';
import DayPickerMoment from 'react-day-picker/moment';
import { strings } from '@services/language';

type DatePickerICAProps = {
  label?: string;
  clearButton?: boolean;
  value?: Date[];
  error?: string;
  disabled?: boolean;
  onSelectDate?: (selectedDate: any) => void;
};

const limitRange = {
  from: new Date(2020, 0),
  to: new Date(2021, 7, 31),
};

export function DatePickerICA({
  label,
  clearButton = true,
  value,
  error = '',
  disabled = false,
  onSelectDate = () => {},
}: DatePickerICAProps) {
  const [fromTo, setFromTo] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [enteredTo, setEnteredTo] = useState<Date | null | undefined>(
    undefined
  );
  const [showDatePicker, setShowDatePicker] = useState(0); // not use boolean since show trigger before hide
  const [keyDate, setKeyDate] = useState(moment().toISOString());

  const toRef = useRef<any>(null);
  const fromRef = useRef<any>(null);

  const isBeforeFirstDay = (day: Date) =>
    fromTo.from && DateUtils.isDayBefore(day, fromTo.from);

  const handleFromChange = (from: RangeModifier['from']) => {
    setFromTo({ ...fromTo, from });
    onSelectDate({ ...fromTo, from });
    setEnteredTo(fromTo.to || from);
  };

  const handleToChange = (to: RangeModifier['to']) => {
    if (to) {
      if (!isBeforeFirstDay(to)) {
        setFromTo({ ...fromTo, to });
        setEnteredTo(to);
      }
    } else {
      setFromTo({ ...fromTo, to });
      setEnteredTo(to);
    }
    onSelectDate({ ...fromTo, to });
  };

  const handleDayMouseEnter = (day: Date) => {
    if (!isBeforeFirstDay(day) && !DateUtils.isDayAfter(day, limitRange.to)) {
      setEnteredTo(day);
    }
  };

  const modifiers = {
    start: fromTo.from ? fromTo.from : new Date(),
    end: enteredTo ? enteredTo : new Date(),
  };

  const dayPickerInputAttr = {
    placeholder: 'DD/MM/YYYY',
    format: 'DD/MM/YYYY',
    formatDate: DayPickerMoment.formatDate,
    parseDate: DayPickerMoment.parseDate,
    overlayComponent: (props: CustomOverlay) =>
      CustomOverlay(
        props,
        setFromTo,
        clearButton,
        onSelectDate,
        fromRef,
        toRef,
        setKeyDate,
        setEnteredTo,
        setShowDatePicker
      ),
    onDayPickerShow: () => {
      setShowDatePicker(showDatePicker + 1);
    },
    onDayPickerHide: () => {
      setShowDatePicker(showDatePicker - 1);
    },
  };

  const dayPickerProps: DayPickerProps = {
    modifiers,
    captionElement: ({ date }) => monthYearLabel(date),
    navbarElement: ({ onPreviousClick, onNextClick }) =>
      Navbar(onPreviousClick, onNextClick),
    weekdayElement: props => Weekday(props),
    renderDay: renderDay,
    showOutsideDays: true,
  };

  const rangePicker = (
    <div
      className="buttonWrapper"
      onClick={() => {
        if (!showDatePicker && !fromTo.from && !fromTo.to && !disabled) {
          fromRef.current.getInput().focus();
        }
      }}
    >
      <div
        className="fixedLabel"
        onClick={() => {
          if (!showDatePicker && (fromTo.from || fromTo.to) && !disabled) {
            fromRef.current.getInput().focus();
          }
        }}
      >
        <CalenderIcon
          style={{
            fill: disabled ? 'var(--grey-border-2)' : 'var(--blue-accent)',
          }}
        />
      </div>

      <div className="selectedValue">
        {
          <>
            {label && (
              <div
                className={`defaultLabel ${
                  !!showDatePicker || fromTo.from || fromTo.to ? 'focus' : ''
                }`}
              >
                {label}
              </div>
            )}
            <div
              className={`InputFromTo ${
                !showDatePicker && !fromTo.from && !fromTo.to ? 'hideInput' : ''
              }`}
            >
              <DayPickerInput
                {...dayPickerInputAttr}
                ref={fromRef}
                value={fromTo.from ? fromTo.from : undefined}
                inputProps={{ disabled: disabled }}
                dayPickerProps={{
                  ...dayPickerProps,
                  selectedDays: [fromTo.from ? fromTo.from : undefined, fromTo],
                  disabledDays: fromTo.to
                    ? {
                        before: limitRange.from,
                        after: fromTo.to,
                      }
                    : {
                        before: limitRange.from,
                        after: limitRange.to,
                      },
                  month: new Date(2020, 2),
                  toMonth: fromTo.to ? fromTo.to : undefined,
                  onDayClick: (_, modifiers) =>
                    !modifiers.disabled && toRef.current.getInput().focus(),
                }}
                onDayChange={handleFromChange}
              />{' '}
              -{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  {...dayPickerInputAttr}
                  ref={toRef}
                  value={fromTo.to ? fromTo.to : undefined}
                  inputProps={{ disabled: disabled }}
                  dayPickerProps={{
                    ...dayPickerProps,
                    selectedDays: [
                      fromTo.from ? fromTo.from : undefined,
                      { ...fromTo, to: enteredTo },
                    ],
                    disabledDays: fromTo.from
                      ? {
                          before: fromTo.from,
                          after: limitRange.to,
                        }
                      : {
                          before: limitRange.from,
                          after: limitRange.to,
                        },
                    month: fromTo.from ? fromTo.from : new Date(2020, 2),
                    fromMonth: fromTo.from ? fromTo.from : undefined,
                    onDayMouseEnter: handleDayMouseEnter,
                    onDayClick: () => {
                      if (!fromTo.from) {
                        fromRef.current.getInput().focus();
                      }
                    },
                  }}
                  onDayChange={handleToChange}
                  onDayPickerHide={() => {
                    setShowDatePicker(showDatePicker - 1);
                    setEnteredTo(fromTo.to || fromTo.from);
                  }}
                />
              </span>
            </div>
          </>
        }
      </div>
    </div>
  );

  return (
    <div
      key={keyDate}
      className={`DatePickerICA ${disabled ? 'disabled' : ''}`}
    >
      <div className="hiddenLabel">
        <span>{label}</span>
      </div>
      {rangePicker}
      <div className="errorWrapper">
        <span>{error}</span>
      </div>
    </div>
  );
}

type CustomOverlay = {
  classNames: {
    [key: string]: string;
  };
  selectedDay: any;
  children: Node;
};

function CustomOverlay(
  { classNames, selectedDay, children, ...props }: CustomOverlay,
  setFromTo: Dispatch<any>,
  clearButton: boolean,
  onSelectDate: (a: any) => void,
  fromRef: React.MutableRefObject<any>,
  toRef: React.MutableRefObject<any>,
  setKeyDate: Dispatch<React.SetStateAction<string>>,
  setEnteredTo: Dispatch<React.SetStateAction<Date | null | undefined>>,
  setShowDatePicker: Dispatch<React.SetStateAction<number>>
) {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        {clearButton && (
          <div className="deselectWrapper">
            <span
              className={
                fromRef.current.getInput().value ||
                toRef.current.getInput().value
                  ? 'active'
                  : ''
              }
              onClick={() => {
                if (
                  !moment(
                    fromRef.current.getInput().value,
                    'DD/MM/YYYY',
                    true
                  ).isValid() ||
                  !moment(
                    toRef.current.getInput().value,
                    'DD/MM/YYYY',
                    true
                  ).isValid()
                ) {
                  setKeyDate(moment().toISOString());
                  setShowDatePicker(0);
                }
                setEnteredTo(undefined);
                setFromTo({
                  from: undefined,
                  to: undefined,
                });
                onSelectDate({
                  from: undefined,
                  to: undefined,
                });
                fromRef.current.getInput().focus();
              }}
            >
              {strings.RESET}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function monthYearLabel(date: Date) {
  return (
    <div className="monthYearLabel">{moment(date).format('MMM YYYY')}</div>
  );
}

function Navbar(onPreviousClick: () => void, onNextClick: () => void) {
  return (
    <div className="navBar">
      <button onClick={() => onPreviousClick()}>
        <ArrowIcon />
      </button>
      <button onClick={() => onNextClick()}>
        <ArrowIcon />
      </button>
    </div>
  );
}

function Weekday(props: WeekdayElementProps) {
  const weekdayName = props.localeUtils.formatWeekdayLong(
    props.weekday,
    props.locale
  );
  return (
    <div className={props.className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </div>
  );
}

function renderDay(day: Date) {
  const date = day.getDate();
  return <div className="dayItem">{date}</div>;
}
