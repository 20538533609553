import styles from './CheckBox.module.scss';
import { ReactComponent as CheckIcon } from '@images/check.svg';
import { ReactNode, useEffect, useState } from 'react';

type CheckBoxProps = {
  checked?: boolean;
  id: string;
  children?: ReactNode;
  handleChange: (id: string, isChecked: boolean) => void;
};

export function CheckBox({
  checked = false,
  id,
  children,
  handleChange,
}: CheckBoxProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        checked={isChecked}
        name={id}
        id={id}
        onChange={() => {
          setIsChecked(!isChecked);
          handleChange(id, !isChecked);
        }}
      />
      <label htmlFor={id} className={styles.checkbox}>
        <CheckIcon className={styles.checkIcon} />
      </label>
      {children}
    </div>
  );
}
