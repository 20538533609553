export function selectEnv<T>(envs: {
  dev?: T;
  staging?: T;
  prod?: T;
  default?: T;
}): T {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return (envs.dev ?? envs.default) as T;
  }
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_TEST_ENV === 'true') {
      return (envs.staging ?? envs.default) as T;
    } else {
      return (envs.prod ?? envs.default) as T;
    }
  } else {
    return (envs.staging ?? envs.default) as T;
  }
}
