import { sendReportAPI, uploadFileAPI, useAPI, useResult } from '@services/api';
import { useLoading } from '@services/hooks';
import { strings } from '@services/language';
import axios from 'axios';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { RadarContext } from '..';
import html2canvas from 'html2canvas';

export function useRadarResultLogic() {
  const {
    datePeriod,
    distance,
    PHOResult,
    siteResult,
    pastSiteResult,
    selectedLocation,
    submited,
    selectedAddress,
    selectedRefNumber,
  } = useContext(RadarContext);
  const [phoList, setPhoList] = useState<
    {
      name: string;
      startDate: string;
      endDate: string;
      revokedDate: string;
      typePHO: string;
      type: string;
      uniqueId: string;
      docUrl: string;
    }[]
  >([]);
  const [siteList, setSiteList] = useState<{ date: string; address: string }[]>(
    []
  );
  const [pastSiteList, setPastSiteList] = useState<
    { date: string; address: string }[]
  >([]);
  const [checkedPHOs, setCheckedPHOs] = useState<boolean[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [mapImage, setMapImage] = useState<Blob | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (siteResult) {
      const siteAddressList = siteResult.data.map(site => ({
        date: site.exp_date
          ? moment(site.exp_date, 'YYYYMMDD').isValid()
            ? moment(site.exp_date, 'YYYYMMDD').format('DD-MM-YYYY')
            : site.exp_date
          : '-',
        address: site.loc_name + ', ' + site.loc_addr + '. ' + site.loc_suburb,
      }));
      setSiteList(siteAddressList);
    } else {
      setSiteList([]);
    }
  }, [siteResult]);

  useEffect(() => {
    if (pastSiteResult) {
      const siteAddressList = pastSiteResult.data.map(site => ({
        date: site.exp_date
          ? moment(site.exp_date, 'YYYYMMDD').isValid()
            ? moment(site.exp_date, 'YYYYMMDD').format('DD-MM-YYYY')
            : site.exp_date
          : '-',
        address: site.loc_name + ', ' + site.loc_addr + '. ' + site.loc_suburb,
      }));
      setPastSiteList(siteAddressList);
    } else {
      setPastSiteList([]);
    }
  }, [pastSiteResult]);

  useEffect(() => {
    if (PHOResult) {
      setCheckedPHOs(Array(PHOResult.data.length).fill(false));
      const phos = PHOResult.data.map(pho => ({
        name: pho.pho_id,
        startDate: pho.start_date
          ? moment(pho.start_date, 'YYYYMMDD').isValid()
            ? moment(pho.start_date, 'YYYYMMDD').format('DD-MM-YYYY')
            : pho.start_date
          : '-',
        endDate: pho.end_date
          ? moment(pho.end_date, 'YYYYMMDD').isValid()
            ? moment(pho.end_date, 'YYYYMMDD').format('DD-MM-YYYY')
            : pho.end_date
          : '-',
        revokedDate: pho.revoked_date
          ? moment(pho.revoked_date, 'YYYYMMDD').isValid()
            ? moment(pho.revoked_date, 'YYYYMMDD').format('DD-MM-YYYY')
            : pho.revoked_date
          : '-',
        typePHO: `${strings.PHO_TYPE}: ${pho.pho_type || '-'}`,
        type: `${strings.BUSINESS_TYPE}: ${
          pho.types_of_commerce_or_region
            ? pho.types_of_commerce_or_region
            : '-'
        }`,
        uniqueId: pho.unique_id,
        docUrl: pho.s3_url || '#',
      }));
      setPhoList(phos);
    } else {
      setPhoList([]);
      setCheckedPHOs([]);
    }
  }, [PHOResult]);

  const resultModalRef = useRef<any>(null);
  const errorModalRef = useRef<any>(null);

  const uploadApi = useAPI(uploadFileAPI);
  const sendReportApi = useAPI(sendReportAPI);

  const onSelectPHO = (id: number, checked: boolean) => {
    const temp = [...checkedPHOs];
    temp[id] = checked;
    setCheckedPHOs(temp);
    if (checked === false && checkedAll === true) {
      setCheckedAll(false);
    } else if (checkedAll === false && !temp.includes(false)) {
      setCheckedAll(true);
    }
  };

  const onSelectAllPHO = (id: string, checked: boolean) => {
    setCheckedAll(checked);
    setCheckedPHOs(checkedPHOs.map(() => checked));
  };

  const onSendReport = () => {
    setMapImage(undefined);
    setLoading(true);
    const mapEl = document.querySelector('#map');
    if (mapEl) {
      html2canvas(mapEl as HTMLElement).then(canvas => {
        canvas.toBlob(blob => {
          if (blob) {
            setMapImage(blob);
          }
        });
      });
    }
  };

  useResult(uploadApi.data, data => {
    setLoading(true);
    uploadFileToS3(data.signedUrl, mapImage!, data.returnUrl);
  });

  useResult(sendReportApi.data, data => {
    if (data) {
      resultModalRef.current.showModal();
    }
  });

  useEffect(() => {
    if (mapImage) {
      uploadApi.request({
        fileName: 'map' + moment().format('DDMMYYhmmss'),
        fileType: 'png',
      });
    }
  }, [mapImage]);

  const uploadFileToS3 = (
    presignedPostUrl: string,
    file: Blob,
    imagePath: string
  ) => {
    axios
      .put(presignedPostUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
      .then(function (response) {
        setLoading(false);
        const checkedIds = checkedPHOs.reduce(
          (arr: number[], checked, index) =>
            checked ? arr.concat(index) : arr,
          []
        );
        const selectedPHOs = phoList.filter((c, i) => checkedIds.includes(i));

        sendReportApi.request({
          pre_date_exposure_sites: pastSiteResult?.data.map(site => ({
            loc_name: site.loc_name,
            loc_addr: site.loc_addr,
            exp_date: site.exp_date,
          }))!,
          exposure_sites: siteResult?.data.map(site => ({
            loc_name: site.loc_name,
            loc_addr: site.loc_addr,
            exp_date: site.exp_date,
          }))!,
          from_date: datePeriod.from!.toISOString(),
          to_date: datePeriod.to!.toISOString(),
          distance: distance,
          address: selectedAddress,
          reference_number: selectedRefNumber,
          map_image_path: imagePath,
          selected_unique_pho_ids: selectedPHOs.map(p => p.uniqueId),
          all_unique_pho_ids: phoList.map(p => p.uniqueId),
        });
      })
      .catch(function (response) {
        setLoading(false);
        errorModalRef.current.showModal();
      });
  };

  useEffect(() => {
    if (
      (uploadApi.error && !uploadApi.loading) ||
      (sendReportApi.error && !sendReportApi.loading)
    ) {
      errorModalRef.current.showModal();
    }
  }, [uploadApi.loading, sendReportApi.loading]);

  useLoading(uploadApi.loading || sendReportApi.loading || loading);

  return {
    distance,
    selectedLocation,
    submited,
    datePeriod,
    phoList,
    siteList,
    pastSiteList,
    checkedPHOs,
    checkedAll,
    resultModalRef,
    errorModalRef,
    siteResult,
    pastSiteResult,
    PHOResult,
    onSelectPHO,
    onSelectAllPHO,
    onSendReport,
    setMapImage,
    disableSendButton: uploadApi.loading || sendReportApi.loading || loading,
  };
}
