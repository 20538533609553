import { useEffect } from 'react';

/**
 * Hook that handle clicks outside of the passed ref
 */
export function useClickOutside(
  ref: any,
  handleClickOut: () => void,
  handleClickIn?: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOut && handleClickOut();
      } else {
        handleClickIn && handleClickIn();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
