import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { selectEnv } from '../env';
import { API, APIState } from './types';
import { useHistory } from 'react-router-dom';
import { toRoute } from '@services/router';
import { useLoading } from '@services/hooks';

export const baseURL = selectEnv({
  dev: 'https://09tkydwljk.execute-api.ap-southeast-2.amazonaws.com',
  staging: 'https://09tkydwljk.execute-api.ap-southeast-2.amazonaws.com',
  prod: 'https://tkjx9hyi52.execute-api.ap-southeast-2.amazonaws.com',
  default: 'https://09tkydwljk.execute-api.ap-southeast-2.amazonaws.com',
});

const possibleErrorPaths = [
  'response.data.message.messages.0.message',
  'response.data.message.0.messages.0.message',
  'message',
];

const client = axios.create({ baseURL });
const emptyFn = () => {};

export function useAPI<T, U extends any[]>(
  api: (...args: U) => API<T>
): APIState<T, U> {
  const [_, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiRef = useRef(api);
  apiRef.current = api;

  const cancelRef = useRef(emptyFn);
  useEffect(() => () => cancelRef.current(), []);

  const history = useHistory();

  useLoading(loading);

  return useMemo(() => {
    const state = {
      loading: false,
      loadingMore: false,
      canLoadMore: false,
      refreshing: false,
      data: undefined,
      error: undefined,
    } as APIState<T, U>;

    function startRequest(...args: U) {
      const config: API<T> = apiRef.current(...args);

      setRequesting(true);

      function setResponse({ data, error, canLoadMore }: any) {
        state.loading = false;
        state.refreshing = false;
        state.loadingMore = false;
        state.canLoadMore = canLoadMore;
        state.data = data ?? state.data;
        state.error = error;
        setRequesting(false);
      }

      //for development
      if (!baseURL || !config.getData) {
        const timeout = setTimeout(() => {
          try {
            setResponse({ data: config.getMockData() });
          } catch (err) {
            setResponse({ error: err });
          }
        }, 1000);
        cancelRef.current = () => clearTimeout(timeout);
        return;
      }

      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const source = axios.CancelToken.source();
      cancelRef.current = source.cancel;

      const clientRequest = (c: AxiosRequestConfig) => {
        return client.request({
          ...c,
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...(c.headers ?? {}),
          },
          cancelToken: source.token,
        });
      };

      config
        .getData(clientRequest, state.data, state.loadingMore)
        .then(setResponse)
        .catch(e => {
          let error: string | null = null;
          possibleErrorPaths.forEach(paths => {
            let current: any = e;
            paths.split('.').forEach(path => {
              if (current && typeof current !== 'string') {
                current = current[path];
              }
            });
            if (!error && typeof current === 'string') {
              error = current;
              if (error.includes('401')) {
                setLoading(true);
                axios
                  .request({
                    url: `${baseURL}/apis/auth/refresh-token/exchange`,
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${refreshToken}`,
                    },
                  })
                  .then(res => {
                    setLoading(false);
                    localStorage.setItem('token', res.data.accessToken);
                    localStorage.setItem('refreshToken', res.data.refreshToken);
                    state.loading = true;
                    startRequest(...args);
                  })
                  .catch(() => {
                    setLoading(false);
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('email');
                    history.push(toRoute('/login'));
                  });
              }
            }
          });
          setResponse({ error: error || 'Network Error' });
        });
    }

    state.request = (...args: U) => {
      if (!state.loading && !state.loadingMore && !state.refreshing) {
        state.loading = true;
        startRequest(...args);
      }
    };

    state.refresh = (...args: U) => {
      if (!state.loading && !state.loadingMore && !state.refreshing) {
        state.refreshing = true;
        startRequest(...args);
      }
    };

    state.loadMore = (...args: U) => {
      if (
        !state.loading &&
        !state.loadingMore &&
        !state.refreshing &&
        state.canLoadMore
      ) {
        state.loadingMore = true;
        startRequest(...args);
      }
    };
    return state;
  }, []);
}

export function useResult<T>(value: T, fn: (v: NonNullable<T>) => void): void {
  const oldValue = useRef(value);
  useEffect(() => {
    oldValue.current != value && value != null && fn(value as NonNullable<T>);
    oldValue.current = value;
  });
}
