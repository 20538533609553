import { useEffect, useRef, useState } from 'react';
import styles from './Dropdown.module.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar/src/simplebar.css';
import { useClickOutside } from '@services/hooks';

interface DropdownItem {
  content: string;
  value: any;
}

type DropdownProps = {
  id: string;
  value: any;
  label?: string;
  error?: string;
  disabled?: boolean;
  list: DropdownItem[];
  loading?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSelect: (content: string, value: any) => void;
  handleFocus?: () => void;
};

export function Dropdown({
  id,
  label,
  value,
  error = '',
  disabled = false,
  list,
  loading = false,
  handleChange,
  onSelect,
  handleFocus,
  ...rest
}: DropdownProps) {
  const [isFocus, setIsFocus] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    undefined
  );

  const mainRef = useRef<any>(null);
  const textareaRef = useRef<any>(null);

  useEffect(() => {
    value && setIsFocus(true);
    !value && setSelectedItem(undefined);
    textareaRef.current.style.height = '';
    textareaRef.current.style.height =
      textareaRef.current.scrollHeight + 1 + 'px';
  }, [value]);

  useClickOutside(mainRef, () => {
    setShowDropdown(false);
  });

  const listEl = list.map((item, index) => (
    <div
      key={`drop-item-${index}`}
      className={styles.dropdownItem}
      onClick={() => {
        setSelectedItem(item);
        onSelect(item.content, item.value);
        setShowDropdown(false);
      }}
    >
      {item.content}
    </div>
  ));

  return (
    <div
      ref={mainRef}
      className={`${styles.container} ${disabled ? styles.disabled : ''}`}
    >
      <div className={styles.inputWrapper}>
        {label && (
          <>
            <div className={styles.hiddenLabel}>
              <span>{label}</span>
            </div>
            <label htmlFor={id} className={isFocus ? styles.focus : ''}>
              <span>{label}</span>
            </label>
          </>
        )}

        <div className={styles.textWrapper}>
          <textarea
            ref={textareaRef}
            id={id}
            name={id}
            value={value}
            className={`${styles.input} ${error !== '' ? styles.error : ''}`}
            disabled={disabled}
            onFocus={() => {
              setShowDropdown(true);
              setIsFocus(true);
              handleFocus && handleFocus();
            }}
            onBlur={e => {
              if (selectedItem && e.target.value !== selectedItem.content) {
                onSelect(selectedItem.content, selectedItem.value);
              }
              e.target.value ? setIsFocus(true) : setIsFocus(false);
            }}
            onChange={e => {
              setShowDropdown(true);
              e.target.style.height = '';
              e.target.style.height = e.target.scrollHeight + 1 + 'px';
              handleChange(e);
            }}
            {...rest}
          ></textarea>

          {loading && (
            <div className={styles.loadingWrapper}>
              <div className={styles.loading}></div>
            </div>
          )}
        </div>

        {showDropdown && value && list.length > 0 && (
          <div className={styles.dropdownList}>
            <SimpleBar style={{ maxHeight: 240 }}>
              <div>{listEl}</div>
            </SimpleBar>
          </div>
        )}
      </div>

      <div className={styles.errorWrapper}>
        <span>{error}</span>
      </div>
    </div>
  );
}
