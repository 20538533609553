import { Fragment, useEffect } from 'react';
import styles from './ExpandContent.module.scss';
import { ReactComponent as PlusIcon } from '@images/plus.svg';
import { ReactComponent as MinusIcon } from '@images/minus.svg';
import ReactMarkdown from 'react-markdown';

type Props = {
  id: string;
  list: {
    title: string;
    content: string;
  }[];
};

export function ExpandContent({ id, list }: Props) {
  const elements = list.map((item, index) => (
    <Fragment key={`ep-${id}-${index}`}>
      <div className={`${styles.item} ${styles.title}`}>
        <h3>{item.title}</h3>
        <div className={styles.toggleButton}>
          <div className={styles.plus}>
            <PlusIcon />
          </div>
          <div className={styles.minus}>
            <MinusIcon />
          </div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={`${styles.item} ${styles.content}`}>
          <ReactMarkdown children={item.content} />
        </div>
      </div>
    </Fragment>
  ));

  useEffect(() => {
    const thisEl = document.getElementById(id);
    const contentEls = thisEl!.querySelectorAll(
      '.' + styles.contentWrapper
    ) as NodeListOf<HTMLElement>;
    const titleEls = thisEl!.querySelectorAll(
      '.' + styles.title
    ) as NodeListOf<HTMLElement>;

    titleEls.forEach((titleEl, index) => {
      titleEl.addEventListener('click', () => addToggleEvent(titleEl, index));
    });

    function addToggleEvent(titleEl: HTMLElement, index: number) {
      const content = contentEls[index];

      if (content.style.maxHeight !== '' && content.style.maxHeight !== '0px') {
        content.style.maxHeight = '0px';
        titleEl.classList.remove(styles.active);
      } else {
        content.style.maxHeight = content.scrollHeight + 'px';
        titleEl.classList.add(styles.active);
      }
    }

    return () => {
      titleEls.forEach((titleEl, index) => {
        titleEl.removeEventListener('click', () =>
          addToggleEvent(titleEl, index)
        );
      });
    };
  }, []);

  return (
    <div id={id} className={styles.container}>
      {elements}
    </div>
  );
}
