export const strings = {
  ABOUT: 'About',
  ADDRESS_OF_BUSINESS_PREMISE: 'Address of Business Premise',
  AN_ACCESS_LINK_SENT:
    'Please check your email to see if you have received the access link.',
  APPLICABLE_PUBLIC_HEALTH_ORDERS: 'Applicable Public Health Orders (PHOs)',
  ASSESSMENT_DATE_PERIOD: 'Assessment Date Period',
  BUSINESS_TYPE: 'Affected Commerce / Premises / Regions',
  BUSINESS_INTERRUPTION_INSURANCE_COVID19_RADAR:
    'Business Interruption Insurance COVID-19 Radar',
  COVID_EXPOSURE_SITES_IN_POLICY_AREA:
    'Current COVID-19 exposure sites in policy area',
  DISTANCE_FROM_BUSINESS_PREMISE: 'Distance from Business Premise',
  EMAIL_ADDRESS: 'Email Address',
  ENTER_YOUR_EMAIL: 'Enter your email',
  END_DATE: 'End date',
  ERROR: 'Error',
  FROM: 'From',
  FAQ: 'FAQ',
  FREQUENTLY_ASKED_QUESTIONS: 'Frequently Asked Questions',
  GO_TO_HOMEPAGE: 'Go to homepage',
  GUIDANCE: 'Guidance',
  HAVE_ISSUES_LOGGING_IN: 'Have issues logging in?',
  HOW_TO_USE: 'How to use',
  IF_YOU_HAVE_ANY_FURTHER_ENQUERIES_PLEASE_CONTACT_US_AT:
    'If you have any further enqueries, please contact us at',
  IF_YOU_NEED_HELP_PLEASE_REFER: 'If you need help, please refer to the',
  IN_POLICY_AREA: 'in policy area',
  INSTRUCTIONS: 'Instructions',
  INVALID_EMAIL: 'Invalid email',
  LEGAL: 'Legal',
  LOG_OUT: 'Log Out',
  LOGIN: 'Login',
  MAP_OF: 'Map of',
  MAP_VIEW: 'Map View',
  NO_DATA_FOUND: 'No data found',
  NOT_FOUND_IN_MAP: 'Not visible on map',
  OKAY: 'Okay',
  OOPS_WRONG: 'Oops! Something went wrong. Please try again.',
  PASS_SITES_IN_AREA: 'Past COVID-19 exposure sites in policy area',
  PAGE_NOT_FOUND: 'Page not found',
  PAGE_UNAVAILABLE:
    'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
  PLEASE_CHECK_YOUR_INBOX: 'Please check your inbox.',
  PLEASE_CONTACT: 'Please contact',
  PLEASE_ENTER_A_NUMBER: 'Please enter a number',
  PLEASE_ENTER_A_DATE: 'Please enter a date period',
  PLEASE_ENTER_A_VALID_DATE: 'Please enter a valid date period',
  PLEASE_SELECT_AN_ADDRESS: 'Please select an address',
  PLEASE_LOG_IN_WITH_YOUR_COMPANY_EMAIL:
    'Please log in with your company email.',
  PHO_TYPE: 'PHO type',
  RADAR: 'Radar',
  REFERENCE_NUMBER: 'Reference Number',
  REPORT_OF_COVID19_EXPOSURES_FROM: 'Report of COVID-19 exposures from',
  REPORT_SENT: 'Report Sent',
  REPORT: 'Report',
  RESET: 'Reset',
  RESEND_EMAIL_IN: 'Resend Email in',
  RESULTS_VIEW: 'Results View',
  REVOKED_DATE: 'Revoked date',
  SELECT_ALL: 'Select All',
  SELECT_THE_PHOS_TO_HAVE_REPORT:
    'Select the PHOs to have their full document sent with the report',
  SEND_REPORT_TO_EMAIL: 'Send Report to Email',
  SHOW_RESULTS: 'Show Results',
  SUPPORT_EMAIL: 'data@insurancecouncil.com.au',
  START_DATE: 'Start date',
  SOMETHING_WENT_WRONG_PLEASE_TRY:
    'Something went wrong with your log in. Please try again.',
  THE_REPORT_HAS_BEEN_SENT_TO: 'The report has been sent to',
  THE_RESULTS:
    'The results can be used to consider a claim under the business interruption insurance policy.',
  THIS_MAY_TAKE_A_FEW_MINUTES: 'This may take a few minutes.',
  UNSUCCESSFUL_LOG_IN: 'Unsuccessful Log In',
  USE_THIS_TOOL:
    'Use this tool to determine the presence of COVID-19 in a policy area.',
  WELCOME_TO_COVID_RADAR: 'Welcome to COVID-19 Radar',
  WEEKS_AGO: 'weeks ago',
  YOUR_EMAIL: 'your email',
};
