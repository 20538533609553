import { API } from '../types';

interface Params {
  email: string;
}

interface Response {
  success: boolean;
}

export function authLoginAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/auth/login',
        method: 'POST',
        data: params,
      });
      return { data: data };
    },
    getMockData: () => {
      return { success: true };
    },
  };
}
