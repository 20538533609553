import { Routes } from './routes';
import { Route, Redirect } from 'react-router-dom';
import { Navbar } from './configs';

export function toRoute<T extends keyof Routes>(route: T, params?: Routes[T]) {
  if (!params) {
    return route;
  }
  const searches: string[] = [];
  Object.keys(params).forEach(k => {
    // @ts-ignore
    searches.push(k + '=' + JSON.stringify(params[k as keyof Routes[T]]));
  });
  return route + '?' + searches.join('&');
}

let radar: any;

export function MainRoute({ children, ...rest }: any) {
  if (!radar && rest.path.includes('/radar')) {
    radar = children;
  }

  const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        token ? (
          location.pathname === '/' ? (
            <Redirect to={{ pathname: '/radar', state: { from: location } }} />
          ) : (
            <>
              {Navbar && <Navbar />}
              <div
                style={{
                  display: location.pathname === '/radar' ? 'block' : 'none',
                }}
              >
                {radar}
              </div>
              {location.pathname !== '/radar' && children}
            </>
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
}

export function AuthRoute({ children, ...rest }: any) {
  const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        token ? (
          <Redirect to={{ pathname: '/radar', state: { from: location } }} />
        ) : (
          children
        )
      }
    />
  );
}
