import { useEffect, useState } from 'react';
import styles from './NavTabs.module.scss';
import { useHistory } from 'react-router-dom';
import { toRoute } from '@services/router';

type NavTabsProps = {
  title: string;
  navTabList: Array<{
    id: string;
    label: string;
    content?: JSX.Element;
  }>;
  activeIndex?: number;
};

export function NavTabs({ title, navTabList, activeIndex }: NavTabsProps) {
  const hash = window.location.hash.substr(1);
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(
    hash || navTabList[activeIndex || 0].id
  );

  useEffect(() => {
    if (hash) {
      setSelectedTab(hash);

      if (!navTabList.map(n => n.id).includes(hash)) {
        history.push(toRoute('/notfound'));
      }
    }
  }, []);

  const tabLinkElements = navTabList.map(tab => (
    <li
      key={`navItem-${tab.id}`}
      className={`${styles.navItem} ${
        tab.id === selectedTab ? styles.active : ''
      }`}
      onClick={() => setSelectedTab(tab.id)}
    >
      <a
        href={'#' + tab.id}
        onClick={e => {
          e.preventDefault();
          window.location.hash = '#' + tab.id;
          document.body.scrollIntoView();
        }}
      >
        {tab.label}
      </a>
    </li>
  ));

  const tabContentElements = navTabList.map(tab => (
    <div
      key={`navContent-${tab.id}`}
      id={tab.id}
      className={`${styles.tabPane} ${
        tab.id === selectedTab ? styles.show : ''
      }`}
    >
      {tab.content}
    </div>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.tabWrapper}>
        <div className={styles.tabSticky}>
          <h1>{title}</h1>
          <ul className={styles.tabList}>{tabLinkElements}</ul>
        </div>
      </div>
      <div className={styles.tabContent}>{tabContentElements}</div>
    </div>
  );
}
