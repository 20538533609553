import SimpleBar from 'simplebar-react';
import { Button, CheckBox, MapICA, Modal } from '@components';
import { strings } from '@services/language';
import styles from './RadarResult.module.scss';
import 'simplebar/src/simplebar.css';
import { useRadarResultLogic } from './logics';
import moment from 'moment';

export default function RadarResult() {
  const {
    datePeriod,
    phoList,
    siteList,
    pastSiteList,
    checkedPHOs,
    checkedAll,
    resultModalRef,
    errorModalRef,
    siteResult,
    pastSiteResult,
    PHOResult,
    onSelectPHO,
    onSelectAllPHO,
    onSendReport,
    disableSendButton,
  } = useRadarResultLogic();

  return (
    <div className={styles.container}>
      <h2>{`${strings.REPORT_OF_COVID19_EXPOSURES_FROM} ${moment(
        datePeriod.from
      ).format('DD/MM/YYYY')} - ${moment(datePeriod.to).format('DD/MM/YYYY')} ${
        strings.IN_POLICY_AREA
      }`}</h2>

      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.phoList}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>
                {strings.APPLICABLE_PUBLIC_HEALTH_ORDERS}
              </div>
              <span className={styles.numberBadge}>
                {PHOResult?.total || 0}
              </span>
            </div>

            <div>{strings.SELECT_THE_PHOS_TO_HAVE_REPORT}</div>

            {PHOResult?.total ? (
              <>
                <CheckBox
                  id={'selectAll'}
                  checked={checkedAll}
                  handleChange={(id, checked) => onSelectAllPHO(id, checked)}
                >
                  <div className={styles.selectAllText}>
                    {strings.SELECT_ALL}
                  </div>
                </CheckBox>
                <div className={styles.phoScroll}>
                  <SimpleBar className={styles.simpleBar}>
                    <div className={styles.phoOptions}>
                      {phoList.map((pho, index) => (
                        <div key={`pho-${index}`} className={styles.phoItem}>
                          <CheckBox
                            id={`pho-checkbox-${index}`}
                            checked={checkedPHOs[index]}
                            handleChange={(id, checked) =>
                              onSelectPHO(index, checked)
                            }
                          >
                            <div
                              className={styles.phoContent}
                              title={`${pho.typePHO}\n\n${pho.type}`}
                            >
                              <a
                                className={styles.phoName}
                                href={pho.docUrl}
                                target="_blank"
                              >
                                {pho.name}
                              </a>
                              <div className={styles.phoDate}>
                                {strings.START_DATE + ': ' + pho.startDate}
                              </div>
                              <div className={styles.phoDate}>
                                {strings.END_DATE + ': ' + pho.endDate}
                              </div>
                              <div className={styles.phoDate}>
                                {strings.REVOKED_DATE + ': ' + pho.revokedDate}
                              </div>
                              <div className={styles.phoType}>
                                {pho.typePHO}
                              </div>
                              <div className={styles.phoType}>{pho.type}</div>
                            </div>
                          </CheckBox>
                        </div>
                      ))}
                    </div>
                  </SimpleBar>
                </div>
              </>
            ) : (
              <div>{strings.NO_DATA_FOUND}</div>
            )}
          </div>

          <div className={styles.siteList}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>
                {strings.COVID_EXPOSURE_SITES_IN_POLICY_AREA}
              </div>
              <span className={styles.numberBadge}>
                {siteResult?.total || 0}
              </span>
            </div>

            <div>{`${strings.FROM} ${moment(datePeriod.from).format(
              'DD/MM/YYYY'
            )} - ${moment(datePeriod.to).format('DD/MM/YYYY')}`}</div>

            {siteResult?.total ? (
              <div className={styles.siteScroll}>
                <SimpleBar className={styles.simpleBar}>
                  <div className={styles.siteWrapper}>
                    {siteList.map((site, index) => (
                      <div key={`site-${index}`} className={styles.siteItem}>
                        <span className={styles.greyText}>{site.date} - </span>
                        {site.address}
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            ) : (
              <div>{strings.NO_DATA_FOUND}</div>
            )}
          </div>

          <div className={`${styles.greyText} ${styles.outerTitle}`}>
            {strings.NOT_FOUND_IN_MAP.toUpperCase()}
          </div>

          <div className={styles.siteList}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>{strings.PASS_SITES_IN_AREA}</div>
              <span className={`${styles.numberBadge} ${styles.greyBadge}`}>
                {pastSiteResult?.total || 0}
              </span>
            </div>

            <div>{`2 ${strings.WEEKS_AGO} (${moment(datePeriod.from)
              .subtract(2, 'week')
              .format('DD/MM/YYYY')} - ${moment(datePeriod.from)
              .subtract(1, 'day')
              .format('DD/MM/YYYY')})`}</div>

            {pastSiteResult?.total ? (
              <div className={styles.siteScroll}>
                <SimpleBar className={styles.simpleBar}>
                  <div className={styles.siteWrapper}>
                    {pastSiteList.map((site, index) => (
                      <div
                        key={`past-site-${index}`}
                        className={styles.siteItem}
                      >
                        <span className={styles.greyText}>{site.date} - </span>
                        {site.address}
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              </div>
            ) : (
              <div>{strings.NO_DATA_FOUND}</div>
            )}
          </div>

          <Button
            className={styles.sendButton}
            type="outlined"
            label={strings.SEND_REPORT_TO_EMAIL}
            width="100%"
            disabled={disableSendButton}
            handleClick={onSendReport}
          />
        </div>

        <div className={styles.right}>
          <div className={styles.title}>
            {strings.MAP_OF + ' ' + strings.COVID_EXPOSURE_SITES_IN_POLICY_AREA}
          </div>

          <div className={styles.mapWrapper}>
            <MapICA />
          </div>
        </div>
      </div>

      <Modal
        ref={resultModalRef}
        header={<h2>{strings.REPORT_SENT}</h2>}
        body={
          <div>
            {strings.THE_REPORT_HAS_BEEN_SENT_TO + ' <'}
            <b>{localStorage.getItem('email') || strings.YOUR_EMAIL}</b>
            {'>. ' + strings.THIS_MAY_TAKE_A_FEW_MINUTES}
          </div>
        }
        footer={
          <>
            <Button
              label={strings.OKAY}
              width="128px"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />

      <Modal
        ref={errorModalRef}
        header={<h2>{strings.ERROR}</h2>}
        body={<div>{strings.OOPS_WRONG}</div>}
        footer={
          <>
            <Button
              label={strings.OKAY}
              width="128px"
              handleClick={() => {
                errorModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}
