import { API } from '../types';

export interface GetAWSLocationTokenResponse {
  ack: string;
  sack: string;
  sst: string;
  expi: string;
}

export function getAWSLocationToken(): API<GetAWSLocationTokenResponse> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/aws/sts',
        method: 'GET',
      });

      return { data: data };
    },
    getMockData: () => {
      return { ack: '', sack: '', sst: '', expi: '' };
    },
  };
}
