import { useState, useEffect } from 'react';
import { authLoginAPI, useAPI, useResult } from '../../services/api';
import { useInput, useLoading } from '../../services/hooks';
import { strings } from '../../services/language';
import { validateEmail } from '../../services/utils';

export function useLoginLogic() {
  const { states: email, bind: bindEmail } = useInput(
    '',
    handleEmailChange,
    false
  );
  const [countDown, setCountDown] = useState(60);
  const [isSent, setIsSent] = useState(false);
  const api = useAPI(authLoginAPI);

  useResult(api.data, d => {
    if (d.success) {
      setIsSent(true);
      localStorage.setItem('email', email.value);
    }
  });

  function handleEmailChange(name: string, value: string) {
    if (value === '') {
      email.setError(strings.ENTER_YOUR_EMAIL);
    } else {
      validateEmail(value)
        ? email.setError('')
        : email.setError(strings.INVALID_EMAIL);
    }
  }

  function onSubmit() {
    if (!email.error && email.value) {
      email.setError('');
      api.request({ email: email.value });
    } else {
      email.setError(strings.ENTER_YOUR_EMAIL);
    }
  }

  useLoading(api.loading);

  const changeHeight = () => {
    document.body.style.height = window.innerHeight + 'px';
  };

  useEffect(() => {
    const timer = isSent
      ? setInterval(() => {
          setCountDown(countDown - 1);
        }, 1000)
      : undefined;

    countDown === 0 && (setIsSent(false), setCountDown(60));

    return () => clearInterval(timer!);
  }, [isSent, countDown]);

  useEffect(() => {
    changeHeight();
    document.getElementById('root')!.style.height = '100%';
    document.body.style.minHeight = '500px';
    document.body.style.background =
      'linear-gradient(90deg, #2A8164 50%, #fff 50%)';
    window.addEventListener('resize', changeHeight);

    return () => {
      window.removeEventListener('resize', changeHeight);
      document.getElementById('root')!.style.height = 'initial';
      document.body.style.height = 'initial';
      document.body.style.minHeight = 'initial';
      document.body.style.background = 'initial';
    };
  }, []);

  return { email, bindEmail, countDown, isSent, api, onSubmit };
}
