import { ExposureSite } from '@services/models';
import { API } from '../types';

interface Params {
  limit?: number;
  offset?: number;
  lat: number;
  lon: number;
  distance: number;
  from_date: string;
  to_date: string;
}

interface Response {
  data: ExposureSite[];
  total_count: number;
}

export function getSitesAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/covid-exposure-sites',
        method: 'GET',
        params: params,
      });
      return { data: data };
    },
    getMockData: () => {
      return {
        data: [
          {
            loc_id: 'a8364d2a-d556-4871-be95-f5582e916565',
            loc_name: 'Alice Springs Airport',
            loc_addr: 'Halsey Drive',
            loc_suburb: 'Alice Springs',
            loc_state: 'NT',
            loc_lon: 133.9034505,
            loc_lat: -23.8016431,
            exp_id: 'd47c6fdc-6c86-4328-bb01-2ce4670fac32',
            exp_classification: 'negative',
            exp_date: '2021-06-25',
            exp_date_desc: 'Friday 25 June 2021',
            exp_start_time: '09:00:00',
            exp_end_time: '15:50:00',
            exp_time_desc: '9am to 3:50pm',
            exp_alert:
              'Get tested immediately and self-isolate until you get a negative result',
            exp_created_at: '2021-06-30T03:10:41.325Z',
            exp_updated_at: '2021-06-30T06:07:41.539Z',
            exp_version: '2',
            exp_status: 'historical',
            exp_src_url:
              'Source deprecated - manually obtained from State Health Department Website',
            exp_src_first_checked: null,
            exp_src_last_checked: null,
          },
          {
            loc_id: '480bc6dd-fa3f-4664-8273-2447b2f5256d',
            loc_name: 'Alice Springs Airport Cafe',
            loc_addr: 'Halsey Drive',
            loc_suburb: 'Alice Springs, Connellan',
            loc_state: 'NT',
            loc_lon: 133.902855838,
            loc_lat: -23.802086956,
            exp_id: 'bb40b94f-a0da-4e9a-b183-e66a9ee7f20f',
            exp_classification: 'isolate',
            exp_date: '2021-06-25',
            exp_date_desc: 'Friday 25 June 2021',
            exp_start_time: '09:20:00',
            exp_end_time: '15:50:00',
            exp_time_desc: '9:20am to 3:50pm',
            exp_alert: 'Get tested immediately and self-isolate for 14 days',
            exp_created_at: '2021-06-30T06:10:19.191Z',
            exp_updated_at: '2021-06-30T06:10:19.191Z',
            exp_version: '1',
            exp_status: 'historical',
            exp_src_url:
              'Source deprecated - manually obtained from State Health Department Website',
            exp_src_first_checked: null,
            exp_src_last_checked: null,
          },
        ],
        total_count: 2,
      };
    },
  };
}
