import { useState } from 'react';

export function useDebounce(func: (...args: any[]) => void, delay: number) {
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  return (...args: any[]) => {
    clearTimeout(timer!);
    const _temp = setTimeout(() => {
      func(...args);
    }, delay);
    setTimer(_temp);
  };
}
