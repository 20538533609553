import styles from './LoginPage.module.scss';
import { useLoginLogic } from './logics';
import { strings } from '@services/language';
import { Button, Input } from '@components';
import { ReactComponent as TickIcon } from '@images/tick.svg';

export default function LoginPage() {
  const { bindEmail, countDown, isSent, onSubmit } = useLoginLogic();

  return (
    <div className={styles.container}>
      <div className={styles.graphicContainer}>
        <div className={styles.graphic}></div>
      </div>
      <div className={styles.loginContainer}>
        <div className={styles.logo}></div>

        <h1>{strings.WELCOME_TO_COVID_RADAR}</h1>

        <div>{strings.PLEASE_LOG_IN_WITH_YOUR_COMPANY_EMAIL}</div>

        <Input id="email" label={strings.EMAIL_ADDRESS} {...bindEmail} />

        {isSent ? (
          <div className={styles.sent}>
            <TickIcon /> {strings.AN_ACCESS_LINK_SENT}
          </div>
        ) : (
          <Button label={strings.LOGIN} width="280px" handleClick={onSubmit} />
        )}

        <div className={styles.resend}>
          {isSent ? strings.RESEND_EMAIL_IN + ' ' + countDown + 'secs' : ''}
        </div>

        <div className={styles.issueText}>
          {strings.HAVE_ISSUES_LOGGING_IN}
          <br />
          {strings.PLEASE_CONTACT}{' '}
          <a href={`mailto:${strings.SUPPORT_EMAIL}`}>
            {strings.SUPPORT_EMAIL}
          </a>
        </div>
      </div>
    </div>
  );
}
