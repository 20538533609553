import { useHistory } from 'react-router-dom';
import { toRoute } from '@services/router';

export function useNavBarLogic() {
  const history = useHistory();

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    history.push(toRoute('/login'));
  }

  return { history, logout };
}
