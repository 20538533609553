import styles from './RangeSlider.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

type RangeSliderProps = {
  label?: string;
  handleChange: (value: number) => void;
};

export function RangeSlider({ label, handleChange }: RangeSliderProps) {
  const marks = {
    5: '5km',
    12: '10km',
    20: '20km',
    50: '50km',
  };

  return (
    <div>
      {label && <div className={styles.label}>{label}</div>}
      <Slider
        className={styles.slider}
        min={3.52}
        max={53}
        marks={marks}
        step={null}
        onChange={value => {
          // Force change value due to design, 5km and 10km are too close
          handleChange(value === 12 ? 10 : value);
        }}
        defaultValue={5}
      />
    </div>
  );
}
