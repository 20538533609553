import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Routes } from './routes';

type KR = keyof Routes;
type R<K extends KR> = Routes[K] extends undefined ? {} : Routes[K];
type Screen<K extends KR> = (props: R<K>) => any;

interface Config<K extends KR> {
  screen: Screen<K>;
  wrapper?: any;
}

const configs: { [key: string]: Config<any> } = {};

function NoScreen() {
  return <div>Screen is not yet implemented.</div>;
}

export function getConfig(key: string): Config<any> {
  if (!configs[key]) {
    configs[key] = { screen: NoScreen };
  }
  return configs[key];
}

export function setScreen<K extends KR>(key: K, screen: Screen<K>) {
  getConfig(key).screen = screen;
  return screen;
}

export function getScreenProps<K extends KR>(key: K) {
  const config = getConfig(key);
  if (!config.wrapper) {
    config.wrapper = () => {
      const params: any = useParams();
      const location = useLocation();
      const query = useMemo(() => {
        const q: any = {};
        const search = new URLSearchParams(location.search);
        search.forEach((v, k) => {
          if (v.startsWith('[') || v.startsWith('{') || !isNaN(Number(v))) {
            q[k] = JSON.parse(v);
          } else {
            q[k] = v;
          }
        });
        return q;
      }, [location.search]);

      return <config.screen {...params} {...query} />;
    };
  }
  return {
    path: key,
    exact: true,
    children: <config.wrapper />,
  };
}

export let Navbar: any;

export function setNavbar(navbar: any) {
  Navbar = navbar;
}
