import { useEffect, useState } from 'react';
import styles from './Input.module.scss';

type InputProps = {
  id: string;
  value: any;
  label?: string;
  error?: string;
  disabled?: boolean;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export function Input({
  id,
  label,
  value,
  error = '',
  disabled = false,
  handleChange,
  ...rest
}: InputProps) {
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    value && setIsFocus(true);
  }, [value]);

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.inputWrapper}>
        {label && (
          <>
            <div className={styles.hiddenLabel}>
              <span>{label}</span>
            </div>
            <label htmlFor={id} className={isFocus ? styles.focus : ''}>
              <span>{label}</span>
            </label>
          </>
        )}

        <input
          id={id}
          name={id}
          value={value}
          className={`${styles.input} ${error !== '' ? styles.error : ''}`}
          disabled={disabled}
          onFocus={() => setIsFocus(true)}
          onBlur={e => {
            e.target.value ? setIsFocus(true) : setIsFocus(false);
          }}
          onChange={e => handleChange(e)}
          {...rest}
        ></input>
      </div>

      <div className={styles.errorWrapper}>
        <span>{error}</span>
      </div>
    </div>
  );
}
