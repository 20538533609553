import { API } from '../types';

type Params = {
  fileName: string;
  fileType: string;
};

interface Response {
  signedUrl: string;
  returnUrl: string;
}

export function uploadFileAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const { data } = await client({
        url: '/apis/upload/file',
        method: 'POST',
        data: params,
      });

      return { data: data };
    },
    getMockData: () => {
      return { signedUrl: 'string', returnUrl: 'string' };
    },
  };
}
