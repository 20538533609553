import { setScreen } from '@services/router';
import RadarForm from './RadarForm/RadarForm';
import RadarResult from './RadarResult/RadarResult';
import { createContext, useMemo, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { ExposureSite, PHO } from '@services/models';

export const RadarContext = createContext<{
  datePeriod: RangeModifier;
  setDatePeriod: React.Dispatch<React.SetStateAction<any>>;
  distance: number;
  setDistance: React.Dispatch<React.SetStateAction<any>>;
  PHOResult: { data: PHO[]; total: number } | undefined;
  setPHOResult: React.Dispatch<React.SetStateAction<any>>;
  siteResult: { data: ExposureSite[]; total: number } | undefined;
  setSiteResult: React.Dispatch<React.SetStateAction<any>>;
  pastSiteResult: { data: ExposureSite[]; total: number } | undefined;
  setPastSiteResult: React.Dispatch<React.SetStateAction<any>>;
  selectedLocation: [number, number];
  setSelectedLocation: React.Dispatch<React.SetStateAction<any>>;
  submited: number;
  setSubmited: React.Dispatch<React.SetStateAction<any>>;
  selectedAddress: string;
  setSelectedAddress: React.Dispatch<React.SetStateAction<any>>;
  selectedRefNumber: string;
  setSelectedRefNumber: React.Dispatch<React.SetStateAction<any>>;
}>({
  datePeriod: {
    from: undefined,
    to: undefined,
  },
  setDatePeriod: () => {},
  distance: 5,
  setDistance: () => {},
  PHOResult: undefined,
  setPHOResult: () => {},
  siteResult: undefined,
  setSiteResult: () => {},
  pastSiteResult: undefined,
  setPastSiteResult: () => {},
  selectedLocation: [0, 0],
  setSelectedLocation: () => {},
  submited: 0,
  setSubmited: () => {},
  selectedAddress: '',
  setSelectedAddress: () => {},
  selectedRefNumber: '',
  setSelectedRefNumber: () => {},
});

setScreen('/radar', () => {
  const [datePeriod, setDatePeriod] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [distance, setDistance] = useState<number>(5);
  const [PHOResult, setPHOResult] = useState<
    { data: PHO[]; total: number } | undefined
  >(undefined);
  const [siteResult, setSiteResult] = useState<
    { data: ExposureSite[]; total: number } | undefined
  >(undefined);
  const [pastSiteResult, setPastSiteResult] = useState<
    { data: ExposureSite[]; total: number } | undefined
  >(undefined);
  const [selectedLocation, setSelectedLocation] = useState<[number, number]>([
    0, 0,
  ]);
  const [submited, setSubmited] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedRefNumber, setSelectedRefNumber] = useState('');

  const value = useMemo(
    () => ({
      datePeriod,
      setDatePeriod,
      distance,
      setDistance,
      PHOResult,
      setPHOResult,
      siteResult,
      setSiteResult,
      pastSiteResult,
      setPastSiteResult,
      selectedLocation,
      setSelectedLocation,
      submited,
      setSubmited,
      selectedAddress,
      setSelectedAddress,
      selectedRefNumber,
      setSelectedRefNumber,
    }),
    [datePeriod, distance, PHOResult, siteResult, pastSiteResult]
  );

  return (
    <RadarContext.Provider value={value}>
      <RadarForm />
      {siteResult && pastSiteResult && PHOResult && <RadarResult />}
    </RadarContext.Provider>
  );
});
