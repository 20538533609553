import { getAWSLocationToken, useAPI } from '@services/api';
import { useEffect } from 'react';

export function useFAQLogic() {
  const qaListAbout = [
    {
      title: 'Why was COVID-19 Radar developed?',
      content: `The COVID-19 Radar Tool was developed to enable both policy holders and insurers to determine whether two of the essential triggers in many Business Interruption (BI) policies will be activated, that is the presence of COVID-19 in the policy area and the Public Health Orders applicable to that business address, at the relevant time.`,
    },
    {
      title: 'What data does the COVID-19 Radar use?',
      content: `The COVID-19 Radar Tool uses source data that has been collated from public health data as well as the Public Health Orders issued by the Federal, State and Territory governments who are responsible for orders and advice that limit the spread of COVID-19.`,
    },
    {
      title: 'What calculations does it do?',
      content: `The COVID-19 Radar Tool identifies whether there were any known  HotSpots and Exposure Sites of COVID-19 cases as well as any applicable Public Health Orders within the policy area, during the relevant date range.`,
    },
  ];

  const qaListUse = [
    {
      title: 'Who can use the COVID-19 Radar Tool?',
      content: `The COVID-19 Radar Tool is intended for use by those insurers who issue business interruption cover and who have or may in the future have claims made by  business interruption policyholders related to COVID-19. In order to use the COVID-19 Radar Tool an organisation has to be authorised to do so by both the Insurance Council of Australia and DSpark Pty Ltd, which is the company that has developed the tool. Each authorised user is required to sign a User Agreement before they can have access and use the COVID-19 Radar Tool . It is not available to policyholders but the report generated by the COVID-19 Radar can be provided to policyholders.`,
    },
    {
      title:
        'Can I use the COVID–19 Radar Tool if the policy does not specify that disease needs to occur within a particular distance from the premises?',
      content: `Yes. The COVID-19 Radar Tool can be used to identify whether a disease has occurred either “at the premises” or within a particular distance from the premises.`,
    },
    {
      title: 'What can I use the COVID-19 Radar Tool for?',
      content: `The COVID-19 Radar Tool will help an insurer to prove the presence of COVID-19 cases within the policy area. It does this by identifying how many Exposure Sites of COVID-19 cases are likely to have occurred  within the policy area. If the tool shows that there is 1 or more cases this can potentially support a COVID-19 claim by a policy holder under their business interruption  policy. The COVID-19 Radar Tool will also identify any relevant Public Health Orders that may have resulted in prevention of access to the business premises or closure of the premises, thus causing interruption or interference to the insured business.`,
    },
    {
      title: 'What information do I need to use the COVID-19 Radar?',
      content: `The following is required:
1. Address of the business premises covered by the policy;
2. Policy radius: this will be the distance from the premises, within which evidence is required to prove the presence of COVID-19, and can be found in the policy documents; and
3. Assessment date range: this will usually be the date range that the business was allegedly interrupted or interfered with as a result of COVID-19.
      `,
    },
  ];

  const qaListResult = [
    {
      title: 'How can I use the results?',
      content: `An insurer can use the results in assessing a COVID-19 related business interruption claim to determine whether there has been a case of COVID-19 within the area specified in the policy and if there have been any relevant Public Health Orders that have resulted in prevention of access or closure of the business premises. The report that contains these results from the COVID-19 Radar Tool can be provided to the policyholder who has made a COVID-19 related business interruption claim to support the insurer’s assessment of such claim.`,
    },
    {
      title: 'My report shows no cases, but this doesn’t seem right to me',
      content: `The COVID-19 Radar Tool has collected data from a range of external sources but is not intended to be the only source of information that can be relied on by both insurers and policyholders. It is open to insurers and policyholders to use other sources of evidence or putt forward their own arguments in respect of the sources of evidence. Please refer to the list that the ICA and its members, in consultation with the National Insurance Brokers Association, issued in October 2021, which provides a general outline of the documents and information required to submit a business interruption claim. The list can be accessed [here](https://insurancecouncil.com.au/wp-content/uploads/2021/10/Business-Interruption-Document_Information_requirements-draft-24.09.pdf).`,
    },
    {
      title: 'Will the results change?',
      content: `The COVID–19 Radar Tool uses data from external sources that may be updated from time to time. The report produced by the COVID-19 will have a date/time stamp to indicate when the data was obtained from the relevant Federal, State or Territory Authority.`,
    },
  ];

  const qaListTroubleshooting = [
    {
      title: 'I cannot find my address',
      content: `If the policyholder’s address cannot be identified or does not show fully, remove any house numbers or apartment numbers and the marker should locate it in the middle of the road.`,
    },
    {
      title: 'The COVID-19 Radar Tool is not working',
      content: `If you have a problem with the COVID–19 Radar Tool, try refreshing the webpage. If this does not work, please contact us.`,
    },
    {
      title:
        'I am unable to download the data or print the report generated from the COVID-19 and no email was received – what can I do?',
      content: `If you have a problem with downloading the data or obtaining your report, you can contact us for technical support.`,
    },
  ];

  const qaListData = [
    {
      title:
        'What happens to the information that is provided in respect of the BI claim?',
      content: `We only collect information about the insured address and no personal information  connecting a policyholder to a particular address or relating to a policyholder is collected by the COVID-19 Radar Tool. This data is used to create the report, which is then emailed to the user. The saved copy is available for 7 days post creation. After 7 days, it is deleted.`,
    },
  ];

  const qaListContact = [
    {
      title:
        'I have a question about the COVID-19 Radar Tool, who can I speak to?',
      content: `If your question isn’t answered in these FAQs please contact us.`,
    },
  ];

  const awsCredentialsApi = useAPI(getAWSLocationToken);
  useEffect(() => {
    awsCredentialsApi.request();
  }, []);

  return {
    qaListAbout,
    qaListContact,
    qaListData,
    qaListResult,
    qaListTroubleshooting,
    qaListUse,
  };
}
