import styles from './NavBar.module.scss';
import { NavLink } from 'react-router-dom';
import { toRoute } from '@services/router';
import { useNavBarLogic } from './logics';
import { strings } from '@services/language';
import { useRef, useState } from 'react';
import { useClickOutside } from '@services/hooks';

export default function NavBar() {
  const { logout } = useNavBarLogic();
  const [show, setShow] = useState(false);

  const navbarRef = useRef<any>(null);

  const navList = [
    {
      url: toRoute('/radar'),
      text: strings.RADAR,
    },
    {
      url: toRoute('/instructions'),
      text: strings.INSTRUCTIONS,
    },
    {
      url: 'https://insurancecouncil.com.au/wp-content/uploads/2021/10/Business-Interruption-Document_Information_requirements-draft-24.09.pdf',
      text: strings.GUIDANCE,
      externalUrl: true,
    },
    {
      url: toRoute('/faq'),
      text: strings.FAQ,
    },
  ];

  const navListEl = navList.map((nav, index) => (
    <li
      key={`nav-item-${index}`}
      onClick={() => {
        setShow(false);
      }}
    >
      {!nav.externalUrl ? (
        <NavLink
          to={nav.url}
          activeClassName={styles.active}
          className={styles.navItem}
          title={nav.text}
        >
          {nav.text}
        </NavLink>
      ) : (
        <a
          href={nav.url}
          className={styles.navItem}
          title={nav.text}
          target="_blank"
        >
          {nav.text}
        </a>
      )}
    </li>
  ));

  useClickOutside(navbarRef, () => {
    setShow(false);
  });

  return (
    <div ref={navbarRef} className={styles.container}>
      <div className={styles.logo}></div>
      <div
        className={styles.menuIcon}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span className={`${styles.navicon} ${show ? styles.show : ''}`}></span>
      </div>
      <div className={`${styles.wrapper} ${show ? styles.show : ''}`}>
        <ul className={styles.navList}>{navListEl}</ul>
        <div className={styles.logoutButton} onClick={logout}>
          {strings.LOG_OUT}
        </div>
      </div>
    </div>
  );
}
