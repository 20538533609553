import { setScreen } from '@services/router';
import styles from './Faq.module.scss';
import { useFAQLogic } from './logics';
import { ExpandContent } from '@components';
import { strings } from '@services/language';

setScreen('/faq', () => {
  const {
    qaListAbout,
    qaListContact,
    qaListData,
    qaListResult,
    qaListTroubleshooting,
    qaListUse,
  } = useFAQLogic();

  return (
    <div className={styles.container}>
      <h1>{strings.FREQUENTLY_ASKED_QUESTIONS}</h1>
      <div>
        <h2>About the COVID-19 Radar Tool</h2>
        <ExpandContent id="about" list={qaListAbout} />
      </div>

      <div>
        <h2>Use of the COVID-19 Radar Tool</h2>
        <ExpandContent id="use" list={qaListUse} />
      </div>

      <div>
        <h2>Results</h2>
        <ExpandContent id="result" list={qaListResult} />
      </div>

      <div>
        <h2>Troubleshooting</h2>
        <ExpandContent id="trouble" list={qaListTroubleshooting} />
      </div>

      <div>
        <h2>Data Protection</h2>
        <ExpandContent id="data" list={qaListData} />
      </div>

      <div>
        <h2>Contact the ICA</h2>
        <ExpandContent id="contact" list={qaListContact} />
      </div>
      <div>
        {strings.IF_YOU_HAVE_ANY_FURTHER_ENQUERIES_PLEASE_CONTACT_US_AT}{' '}
        <a href={`mailto:${strings.SUPPORT_EMAIL}`}>{strings.SUPPORT_EMAIL}</a>
      </div>
    </div>
  );
});
